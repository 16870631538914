

























import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: { OPaymentList: () => import('@/components/organisms/globalTools/paymentMethod/o-payment-list.vue')},

  setup(){
    const model = reactive({
      type: ''
    })

    const types = [
      {
        name: 'Wszystkie',
        value: ''
      },
      {
        name: 'Konto PayU',
        value: 'PayU'
      },
      {
        name: 'Konto bankowe',
        value: 'Transfer'
      }
    ]

    return { model, types }
  }
})
